







































































import { Component, Ref, Vue } from "vue-property-decorator";
import {
  AuditFlowScope,
  DataDictionaryDtoPagedResultDto,
  UserTaskDto,
} from "@/api/appService";
import api from "@/api";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "TaskManagerDetail",
  components: {
    AgileAuditTag,
    AuditBlock,
  },
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.id == type) {
          result = item.displayName;
          return;
        }
      });
      return result;
    },
    formatUser(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class TaskManagerDetail extends Vue {

  detail: UserTaskDto = {};
  userList: any[] = [];
  importantLevel: any = []; // 紧急程度

  statusList: any[] = [];
  created() {
    if (this.$route.params.id) {
      this.fetchDetail();
    }
    this.fetchUserList();
    this.fetchDataDictionary();
    this.fetchEnumType();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "TaskStatus",
      })
      .then((res) => {
        this.statusList = res;
      });
  }

  async fetchUserList() {
    await api.fundUser.getFundUserList({}).then((res: any) => {
      this.userList = res ?? [];
    });
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "ImportantLevel",
        maxResultCount: 1000,
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.importantLevel = res.items;
      });
  }

  // 获取详情
  async fetchDetail() {
    api.userTask.get({ id: Number(this.$route.params.id) }).then((res) => {
      this.detail = { ...res };
    });
  }


  get auditApi(){
    return {
      canAudit: api.userTask.canAudit,
      userAudit: api.userTask.audit
    } as AuditApi
  }


  // 返回
  private cancel() {
    this.$router.back();
  }
}
